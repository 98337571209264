// ==== IMAGE LOADING ==== //

import lazySizes from 'lazysizes'

// Native lazyloading support
if (__FEATURES__.imageNativeLazyload) {
	import(
		'lazysizes/plugins/native-loading/ls.native-loading' /* webpackChunkName: "lazysizesNativeLoading", webpackMode: "eager" */
	)
}

// No need for explicit initialization with this method
Object.assign(lazySizes.cfg, {
	expand: 800, // How many pixels to expand the viewport for loading purposes
	expFactor: 3, // Increase the expanded viewport when network is idling
	minSize: 80, // A little larger than the LQIP; this is used to generate `data-sizes`
	setLoadingAttribute: false, // Only for use with the "native loading" plugin; we already add the `loading` attribute
})

if (__FEATURES__.imageNativeLazyload) {
	const imageLoader = (): void => {
		const documentImages = document.querySelectorAll('img[loading="lazy"]')
		const nativeLazyLoadingSupport = 'loading' in HTMLImageElement.prototype

		if (documentImages) {
			for (const documentImage of documentImages) {
				if (documentImage instanceof HTMLImageElement) {
					if (
						nativeLazyLoadingSupport &&
						documentImage.getAttribute('loading') &&
						documentImage.height &&
						documentImage.width &&
						'src' in documentImage.dataset &&
						documentImage.dataset.src
					) {
						documentImage.src = documentImage.dataset.src
					} else if ('lqip' in documentImage.dataset && documentImage.dataset.lqip) {
						documentImage.src = documentImage.dataset.lqip
					} else if ('src' in documentImage.dataset && documentImage.dataset.src) {
						documentImage.src = documentImage.dataset.src
					}
				}
			}
		}
	}

	imageLoader()
	window.addEventListener('DOMContentLoaded', imageLoader)
}
